const CardData = {
'Måleri':[
    {
    'cardImg':'img/ebo/img-01.jpg',
    'cardImgAlt':'Linköping',
    'cardTitle':'Linköping',
    'cardSubtitle':'Collage, pastell, akryl på duk',
    'cardText':''
},
{
    'cardImg':'img/ebo/img-02.jpg',
    'cardImgAlt':'Plocka godbitar',
    'cardTitle':'Plocka godbitar',
    'cardSubtitle':'Assemblage på pannå',
    'cardText':''
},
{
    'cardImg':'img/ebo/img-03.jpg',
    'cardImgAlt':'Bäver på väg',
    'cardTitle':'Bäver på väg',
    'cardSubtitle':'Olja på duk',
    'cardText':''
},
{
    'cardImg':'img/ebo/img-04.jpg',
    'cardImgAlt':'Serengeti',
    'cardTitle':'Serengeti',
    'cardSubtitle':'Akryl på canvas',
    'cardText':''
},
{
    'cardImg':'img/ebo/img-05.jpg',
    'cardImgAlt':'Dansande par',
    'cardTitle':'Dansande par',
    'cardSubtitle':'Akryl på pannå',
    'cardText':''
},
{
    'cardImg':'img/ebo/img-06.jpg',
    'cardImgAlt':'Elefant',
    'cardTitle':'Elefant',
    'cardSubtitle':'Akryl på gardin',
    'cardText':''
},
{
    'cardImg':'img/ebo/img-07.jpg',
    'cardImgAlt':'Pingvin',
    'cardTitle':'Pingvin',
    'cardSubtitle':'Akryl på canvas',
    'cardText':''
},
{
    'cardImg':'img/ebo/img-08.jpg',
    'cardImgAlt':'Utan titel',
    'cardTitle':'Utan titel',
    'cardSubtitle':'Akryl på gardin',
    'cardText':''
}
],
'Betong':[
    {
        'cardImg':'img/ebo/img-09.jpg',
        'cardImgAlt':'Igelkott',
        'cardTitle':'Igelkott',
        'cardSubtitle':'Flytspackel',
        'cardText':''
    },
    {
        'cardImg':'img/ebo/img-10.jpg',
        'cardImgAlt':'Tomte',
        'cardTitle':'Tomte',
        'cardSubtitle':'Flytspackel',
        'cardText':''
    },
    {
        'cardImg':'img/ebo/img-11.jpg',
        'cardImgAlt':'Tomte',
        'cardTitle':'Tomte',
        'cardSubtitle':'Porslinsfärg på kakel',
        'cardText':''
    },
    {
        'cardImg':'img/ebo/img-12.jpg',
        'cardImgAlt':'Rabarberblad',
        'cardTitle':'Rabarberblad',
        'cardSubtitle':'Betong',
        'cardText':''
    },
    {
        'cardImg':'img/ebo/img-13.jpg',
        'cardImgAlt':'Fågelbad/fat',
        'cardTitle':'Fågelbad/fat',
        'cardSubtitle':'Flytspackel',
        'cardText':''
    },
    {
        'cardImg':'img/ebo/img-14.jpg',
        'cardImgAlt':'Fågelbad/fat',
        'cardTitle':'Fågelbad/fat',
        'cardSubtitle':'Flytspackel',
        'cardText':''
    },
    {
        'cardImg':'img/ebo/img-15.jpg',
        'cardImgAlt':'Fågelbad',
        'cardTitle':'Fågelbad',
        'cardSubtitle':'Betong',
        'cardText':''
    }
]
};

export default CardData;