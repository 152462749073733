import React, { useState, useEffect } from 'react';
import TabbedCardListArea from '../elements/TabbedCardListArea';
import Person from '../elements/Person';
import CardData from './EboCardData';
import data from './EboData';

const Ebo = () => {

    const [isTabsVisible, setIsTabsVisible] = useState(false);
    const tabRef = React.createRef();

    const handleShowTabs = () => {
        setIsTabsVisible(!isTabsVisible);
    }

    useEffect(() => {
        if (isTabsVisible) tabRef.current.scrollIntoView({behavior: 'smooth'});
      });

    return (
        <div>
            <Person data={data}>
                {/* Extra Ebo imgs */}
                <div className="text-center">
                    <button className="text-xl bg-gray-100 rounded-md p-2 m-1 hover:bg-gray-300 focus:outline-none" onClick={handleShowTabs}>{(isTabsVisible?"Dölj":"Visa") + "  bilder"}</button>
                </div>
                <div id="tabContainer" ref={tabRef} className={(isTabsVisible?"block":"hidden")}>
                    <TabbedCardListArea carddata={CardData} />
                </div>
            </Person>
        </div>
    );
}

export default Ebo;