import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {

    return (
        <div className="flex flex-col justify-evenly h-full">
            <div className="flex justify-center items-center">
                <img className="block w-64 h-64 my-16 rounded-full shadow-lg" src="img/couple.jpg" alt="" />
            </div>
            <div className="flex flex-col justify-center items-center">
                <h1 className="text-2xl font-bold w-64 mx-8 mb-4 px-2">Välkommen till ...</h1>
                <Link className="block text-xl font-bold w-64 my-1 px-2" to="/ulf">Ulf Öhrling</Link>
                <Link className="block text-xl font-bold w-64 my-1 px-2" to="/elisabeth">Elisabeth Brandt Öhrling</Link>
                <Link className="block text-xl font-bold w-64 my-1 px-2" to="/ebo"><img className="inline-block mb-2 h-5 w-auto" src="img/ebo.png" alt="EBÖ" />s <span className="text-red-900 font-bold">färg</span> <span className="text-green-900">&amp;</span> <span className="text-blue-900 italic">form</span></Link>
            </div>
        </div>
    );
}

export default Home;