const Data = {
    'profile_img':'img/elisabeth/profile_img.jpg',
    'name':'Elisabeth Brandt Öhrling',
    'title':'Bildlärare, Fritidspedagog',
    'description':'Född 1963 i Vänersborg. Sedan 2005 bosatt i Linköping. Utbildad fritidspedagog och bildlärare med många års erfarenhet av arbete med barn. Har läst Konst och Design på Liljeholmens folkhögskola med inriktning på keramik och kroki. Har ett stort behov att skapa och tillbringar mycket tid i min ateljé. På sommaren tillbringar jag min tid i min kolonistuga, Bettebo. När tillfälle ges brukar jag åka och dansa. Gift med Ulf sedan 2014.',
    'puffs':
    [
        {
            'icon':'img/elisabeth/bettebo_icon.jpg',
            'alt':'Bettebo',
            'title':'Bettebo',
            'text':'Köpte min kolonistuga 2007 och har anlagt trädgården och odlar lite av varje. I det nyrenoverade växthuset växer gurkor, fröer förgros och jag tar gärna en kopp kaffe där när det blåser kalla vindar. Älskar närheten till trädgård, njuter av stillheten vid tidiga morgnar och solnedgångar. Flyttar dit så fort nätterna är hanterbara och hem igen när mörkret och kylan blir för påtaglig.',
        },
        {
            'icon':'img/elisabeth/dans2_icon.jpg',
            'alt':'Dans',
            'title':'Dans',
            'text':'Gick min första buggkurs i början av 80-talet och återupptog dansen 2010 igen. Säger inte nej till en bra bugg eller en härlig fox.',
        },
        {
            'icon':'img/elisabeth/pyssel_icon.jpg',
            'alt':'Pyssel',
            'title':'Pyssel',
            'text':'Älskar att pyssla och har samlat blad, grenar och svampar till att binda kransar, torkat frukt och grönsaker samt kladdat med saltdeg och cernitlera. Jag har inrett dockskåp och byggt tittskåp. Jag bubblar av kreativitet och har alltid något på gång.',
        }
    ],
    'email':'elibra63(at)hotmail.com',
    'fb_link':'https://www.facebook.com/elisabeth.brandt.756',
    'fb_text':'facebook.com/elisabeth.brandt.756'
}

export default Data;