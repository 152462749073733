import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Navbar from './components/elements/Navbar';
import Home from './components/pages/Home';
import Ulf from './components/pages/Ulf';
import Elisabeth from './components/pages/Elisabeth';
import Ebo from './components/pages/Ebo';


function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/ulf" component={Ulf} />
      <Route exact path="/elisabeth" component={Elisabeth} />
      <Route exact path="/ebo" component={Ebo} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
      </Switch>
    </Router>
);
}

export default App;
