import React from 'react';
import Person from '../elements/Person';
import data from './ElisabethData';

const Elisabeth = () => {

    return (
        <Person data={data} />
    );
}

export default Elisabeth;