import React from 'react';

function Person(props) {
    const { data } = props;

    return (
        <div className="lg:w-3/4 lg:mx-auto shadow-md">
            <div className="md:flex md:justify-evenly bg-gray-100 border-b-2">
                <div className="md:w-1/2">
                    <img className="object-cover h-full w-full" src={data.profile_img} alt="Profile"/>
                </div>
                <div className="p-2 md:w-1/2">
                    <h1 className="text-2xl font-bold">{data.name}</h1>
                    <h2 className="text-2xl italic">{data.title}</h2>
                    <p className="text-md mt-1">{data.description}</p>
                </div>
            </div>

            <div className="sm:flex sm:justify-evenly bg-white">
                {
                    data.puffs.map((puff, index) => (
                        <div className="sm:w-1/3 p-2" key={'puff'+index}>
                            <div className="flex items-center">
                            <div>
                                <img className="inline-block w-24 h-24 mr-2 rounded-full border-2" src={puff.icon} alt={puff.alt} />
                            </div>
                            <div>
                            <h2 className="text-2xl italic">{puff.title}</h2>
                            </div>
                            </div>
                            <p className="text-md mt-1">{puff.text}</p>
                        </div>
                    ))
                }
            </div>

            <div>
                <div className="sm:flex sm:justify-evenly sm:items-center p-2 bg-gray-800 text-white">
                    <p className="sm:inline-block text-sm font-bold">Kontakt</p>
                    <p className="sm:inline-block text-sm font-mono">{data.email}</p>
                    <p className="sm:inline-block text-sm font-mono"><a href={data.fb_link} rel="noopener noreferrer" target="_blank">{data.fb_text}</a></p>
                </div>
            </div>
            { props.children }
        </div>
    );
}

export default Person;