import React, { useState } from 'react';
import FluidCardList from './FluidCardList';

const TabbedCardListArea = (props) => {
    const carddata = props.carddata;
    const categories = Object.keys(carddata);
    const [activeTab, setActiveTab] = useState(categories[0]);

    const handleTabClick = (tabid) => {
        setActiveTab(tabid);
    }

    const luClass = "list-none p-4";
    const liClass = "inline-block cursor-pointer px-4 bg-white hover:bg-green-100 border";

    return (
        <div className="">
            <ul className={luClass}>
                {
                    categories.map((category,idx) => (
                        <li className={liClass + (activeTab === category?" bg-green-300":"")} onClick={() => handleTabClick(category)} key={category+idx}>{category}</li>
                        )
                    )
                }
                {/* <li className={liClass + (activeTab === "area-1"?" bg-green-300":"")} onClick={() => handleClick("area-1")}>{props.children[0].props.category}</li>
                <li className={liClass + (activeTab === "area-2"?" bg-green-300":"")} onClick={() => handleClick("area-2")}>{props.children[1].props.category}</li>
                <li className={liClass + (activeTab === "area-3"?" bg-green-300":"")} onClick={() => handleClick("area-3")}>Area 3</li> */}
            </ul>

            <div className="p-4">
                {
                    categories.map((category,idx) => (
                    <div key={"area_"+category+idx} className={"bg-white py-4 " + (activeTab === category ? "block" : "hidden")}>
                        <FluidCardList cardlist={carddata[category]} category={category} />
                    </div>
                    )
                    )
}
            </div>
        </div>
    );
}

/* .tabs { list-style: none; }
.tabs li { display: inline; }
.tabs li a { color: black; float: left; display: block; padding: 4px 10px; margin-left: -1px; position: relative; left: 1px; background: white; text-decoration: none; }
.tabs li a:hover { background: #ccc; } 

*/

export default TabbedCardListArea;