const Data = {
    'profile_img':'img/ulf/profile_img.jpg',
    'name':'Ulf Öhrling',
    'title':'Kreativ kodare',
    'description':'Född i Sundsvall 1959. Sedan 1983 bosatt i Linköping. Jag är en person som har ett stort intresse av att upptäcka och lära vilket även gjort mig till en bra pedagog. Mitt liv har präglats av kultur/teknik och för mig är det inte speciellt konstigt att både fotografera, spela musik etc och att lära mig hur utrustningen fungerar. Jag har haft stort utbyte av att samarbeta med konstnärer och andra kulturarbetare i olika projekt. På senare år har jag blivit helt uppslukad av jonglering. Gift med Elisabeth sedan 2014.',
    'puffs':
    [
        {
            'icon':'img/ulf/tangentbord_icon.jpg',
            'alt':'Tangentbord',
            'title':'IT/matematik',
            'text':'Sedan slutet av 70-talet har datorer och programmering varit intressant för mig och jag programmerar såväl privat som i arbete. Är i stort sett självlärd och hanterar ett antal programspråk, t ex Python, C, C++, Javascript. Intresset för matematik och matematikhistoria har vuxit med åren och jag grottar gärna ner mig i t ex talteori.',
        },
        {
            'icon':'img/ulf/jonglering_icon.jpg',
            'alt':'Jonglering',
            'title':'Jonglering',
            'text':'Efter en halkolycka i november 2016 var jag tvungen att genomgå operation med efterföljande smärtsamma rehab. För att göra rehaben lite roligare satte jag upp målet att lära mig 3-bollars jonglering. Nu tränar jag regelbundet med Linköpings Nycirkusförening och jag hanterar numera såväl bollar som ringar och käglor.',
        },
        {
            'icon':'img/ulf/musik_icon.jpg',
            'alt':'Musik',
            'title':'Musik',
            'text':'En stor del av mitt liv har präglats av musik både som musiker och lyssnare. Musicerandet har bestått av en blandad repertoar i olika band på olika instrument. Jag har också använt mig av studioutrustning av alla de slag, så som syntar och effekter. Det digitala har passat mig bra här.',
        }
    ],
    'email':'ulfohr(at)gmail.com',
    'fb_link':'https://www.facebook.com/ulf.ohrling',
    'fb_text':'facebook.com/ulf.ohrling'
}

export default Data;