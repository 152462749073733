import React from 'react';
import Person from '../elements/Person';
import data from './UlfData';

const Ulf = () => {

    return (
        <Person data={data} />
    );
}

export default Ulf;