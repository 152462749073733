import React, { useState } from 'react';

const ModalImageCard = (props) => {
    const {data} = props;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = () => {
        setIsModalOpen(!isModalOpen);
    }

    return (
        <div>
            <div className="my-4 bg-white rounded-md overflow-hidden shadow-lg">
                {/* image + alt */}
                <div className="h-64 flex justify-center bg-white">
                    <img className="m-0 object-scale-down pointer-events-none sm:pointer-events-auto cursor-default sm:cursor-pointer" src={data.cardImg} alt={data.cardImgAlt} onClick={handleClick}  />
                </div>
                {/* <img className="m-0 object-scale-down cursor-pointer" src={data.cardImg} alt={data.cardImgAlt} onClick={handleClick} /> */}
                {/* title */}
                <div className="text-lg font-bold leading-tight text-gray-900 text-center">{data.cardTitle}</div>
                {/* subtitle */}
                <div className="text-sm italic leading-snug text-gray-700 text-center">{data.cardSubtitle}</div>
                {/* text */}
                <p className="text-xs md:text-sm my-2 leading-none">{data.cardText}</p>
            </div>
            {/* modal */}
            <div className={(isModalOpen?"block ":"hidden ") + "fixed z-10 pt-6 left-0 top-0 w-full h-full overflow-auto bg-black bg-opacity-90"}>
                <div className="text-xl font-bold mx-2 text-white text-center">{data.cardTitle}</div>
                <div className="text-sm italic mx-3 text-white text-center">{data.cardSubtitle}</div>
                <span className="absolute top-0 right-0 mt-1 mr-2 text-white text-5xl font-bold hover:text-gray-600 cursor-pointer" onClick={handleClick}>&times;</span>
                <img className="m-auto mt-1 block max-h-full" src={data.cardImg} alt={data.cardImgAlt} />
            </div>
        </div>
    );
}

export default ModalImageCard;
