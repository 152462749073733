import React from 'react';
import ModdalImageCard from '../elements/ModalImageCard';

const FluidCardList = (props) => {
    const cardlist = props.cardlist;
    const category = props.category;
    
    return (
        <div className="flex flex-wrap justify-around">
            {
                cardlist.map((data, idx) => {
                    
                    return (
                        <div className="w-full sm:w-1/3 md:w-1/4 m-1"  key={category+idx}>
                            <ModdalImageCard data={data} />
                        </div>
                    )
                })
            }
            
        </div>
    );
}

export default FluidCardList;