import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    }

    const handleLinkClick = () => {
        setIsOpen(false);
    }

    const activeClassName = "bg-gray-700";

    return (
        <nav className="sm:flex sm:justify-between bg-gray-800 lg:w-3/4 lg:mx-auto ">
            <div className="flex items-center justify-between px-4 py-3">
                <div>
                    <Link className="" onClick={handleLinkClick} to="/">
                    <img className="h-8 w-auto" src="img/ohrling_white.png" alt="Öhrling" />
                    </Link>
                </div>

                <div className="sm:hidden">
                    <button onClick={handleButtonClick} type="button" className="block h-8 w-8 text-center p-0 focus:outline-none">
                    {isOpen?<img className="h-6 mx-auto" src="img/cross.svg" alt="" />:<img className="h-6 mx-auto" src="img/hamburger.svg" alt="" />}
                    </button>
                </div>
            </div>

            <div className={ (isOpen?"block ":"hidden ")+"px-2 py-3 sm:flex"}>
                <NavLink activeClassName={activeClassName} onClick={handleLinkClick} className="block px-2 py-1 text-white font-semibold uppercase hover:bg-gray-700 focus:outline-none md:mx-3" to="/ulf">Ulf</NavLink>
                <NavLink activeClassName={activeClassName} onClick={handleLinkClick} className="block px-2 py-1 text-white font-semibold uppercase hover:bg-gray-700 focus:outline-none md:mx-3" to="/elisabeth">Elisabeth</NavLink>
                <NavLink activeClassName={activeClassName} onClick={handleLinkClick} className="block px-2 py-1 text-white font-semibold uppercase hover:bg-gray-700 focus:outline-none md:mx-3" to="/ebo">Ebö</NavLink>          
            </div>
      </nav>
        );
}

export default Navbar;