const Data = {
    'profile_img':'img/ebo/profile_img.jpg',
    'name':'EBÖs färg & form',
    'title':'Konst och konsthantverk',
    'description':'Jag heter Elisabeth Brandt Öhrling och EBÖs färg & form är ett projekt där jag låter kreativiteten flöda fritt. Jag har alltid pysslat och målat såväl i mitt arbete som på fritiden men det tog fart 2017 då jag utbildade mig till bildlärare. Efter det har jag gått 1 år på Liljeholmens folkhögskola och studerat konst och design med inriktning på keramik och teckning. I min ateljé experimenterar jag med måleri och gjutning i betong. Återbrukar material som lampor och porslin vilket ger kasserade saker en andra chans.',
    'puffs':
    [
        {
            'icon':'img/ebo/betong_icon.jpg',
            'alt':'Betong',
            'title':'Betong',
            'text':'2019 köpte jag min första 20 kg säck med finbetong och kolonin fylldes av ljuslyktor och ett och annat rabarberblad. Jag köpte latexformar och gjöt lite tomtar. Utvecklar för närvarande min fat/fågelbad med textiltryck och blomblad. Gjuter även ytterkrukor med hjälp av textilier.'
        },
        {
            'icon':'img/ebo/maleri_icon.jpg',
            'alt':'Måleri',
            'title':'Måleri',
            'text':'Målar för det mesta i akryl men ibland blir det även olja. Oljan torkar dock mycket långsammare än akryl. Blandar olika tekniker och material. Målar på det mesta t ex rullgardin, pussel och pallar.',
        },
        {
            'icon':'img/ebo/aterbruk2_icon.jpg',
            'alt':'Återbruk',
            'title':'Återbruk',
            'text':'Jag tycker det slängs alldeles för mycket i samhället och jag gillar att limma kakfat, måla på rullgardin, måla på andra tavlor eller trasiga stolar. Använder gardinstänger till blomstöd, gardiner i stället för fågelnät över jordgubbarna. Burkar blir till ljuslyktor, trasiga stolar och gavlar blir perfekta blomstöd. Håller mig framme vid byggen och tar gärna emot träskivor, kakelplattor etc.',
        }
    ],
    'email':'elibra63(at)hotmail.com',
    'fb_link':'https://www.facebook.com/EB%C3%96s-f%C3%A4rg-o-form-101353948235134',
    'fb_text':'Facebook "EBÖs färg o form"'
}

export default Data;